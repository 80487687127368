var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wameed-dashboard-page-content"},[_c('page-header',{attrs:{"title":_vm.$t('coupons.title'),"sub-title":_vm.$t('coupons.subtitle'),"btn-title":_vm.$t('coupons.add_new_coupons'),"route-name":"addCoupon"}}),_c('div',[_c('filter-header',{attrs:{"content":_vm.content},on:{"applyFilter":_vm.applyFilter,"resetFilter":_vm.resetFilter,"orderApplicants":_vm.orderCoupons}})],1),_c('section',{staticClass:"\n      wameed-dashboard-page-content_body\n      d-flex\n      flex-column\n      justify-content-between\n      overflow-hidden\n    "},[_c('div',{staticClass:"d-flex flex-wrap flex-1 px-2"},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[_c('div',{staticClass:"w-table d-flex flex-column"},[(_vm.getAllCoupons)?_c('w-tables',{attrs:{"per-page":_vm.filterData.per_page,"items":_vm.getAllCoupons,"fields":_vm.fields,"status":_vm.couponStatus,"custimized-items":[
              { name: 'coupon_type' },
              { name: 'action' },
              { name: 'created_at' },
              { name: 'end_date' },
              { name: 'services' },
              { name: 'coupon_usage_count' },
              { name: 'discount_rate' } ]},scopedSlots:_vm._u([{key:"coupon_type",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(_vm._s(_vm.$t('coupons.type.' + data.item.coupon_type)))])])]}},{key:"created_at",fn:function(ref){
            var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.moment(data.item.created_at).format('ll'))+" ")]}},{key:"end_date",fn:function(ref){
            var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.moment(data.item.end_date).format('ll'))+" ")]}},{key:"services",fn:function(ref){
            var data = ref.data;
return [(data.item.services == 1)?_c('span',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('common.one_package'))+" ")]):_vm._e(),(data.item.services >= 2 && data.item.services < 11)?_c('span',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(data.item.services + ' ' + _vm.$t('common.packages'))+" ")]):_vm._e(),(data.item.services >= 11)?_c('span',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(data.item.services + ' ' + _vm.$t('common.package'))+" ")]):_vm._e()]}},{key:"coupon_usage_count",fn:function(ref){
            var data = ref.data;
return [(data.item.coupon_usage_count == 0)?_c('span',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('common.not_used'))+" ")]):_vm._e(),(data.item.coupon_usage_count == 1)?_c('span',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('common.only_once'))+" ")]):_vm._e(),(data.item.coupon_usage_count == 2)?_c('span',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('common.twice'))+" ")]):_vm._e(),(
                  data.item.coupon_usage_count >= 3 &&
                  data.item.coupon_usage_count < 11
                )?_c('span',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(data.item.coupon_usage_count + ' ' + _vm.$t('common.times'))+" ")]):_vm._e(),(data.item.coupon_usage_count >= 11)?_c('span',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(data.item.coupon_usage_count + ' ' + _vm.$t('common.once'))+" ")]):_vm._e()]}},{key:"discount_rate",fn:function(ref){
                var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.discount_rate + '%')+" ")]}},{key:"action",fn:function(ref){
                var data = ref.data;
return [_c('b-dropdown',{attrs:{"variant":"background","toggle-class":"text-decoration-none rounded-10 px-2 py-2 ","menu-class":"rounded-14","no-caret":"","dropleft":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('vertical-dots-icon',{staticClass:"mx-3 my-1"})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"link-class":"py-0min-height: 52px;"},on:{"click":function($event){return _vm.couponDetails(data.item.id)}}},[_c('span',{staticClass:"text-regular-14 text-font-secondary"},[_c('eyeon-icon',{staticClass:"mx-2"}),_vm._v(" "+_vm._s(_vm.$t('common.show_details'))+" ")],1)]),(data.item.status !=='expired')?_c('b-dropdown-item',{attrs:{"link-class":"py-0min-height: 52px;"},on:{"click":function($event){return _vm.toggleCouponStatus(data.item.id)}}},[(data.item.status ==='active'||data.item.status ==='soon')?_c('span',{staticClass:"text-regular-14 text-danger"},[_c('stop2-icon',{staticClass:"mx-2"}),_vm._v(" "+_vm._s(_vm.$t('common.stop'))+" ")],1):_vm._e(),(data.item.status ==='not_active')?_c('span',{staticClass:"text-regular-14 text-main"},[_c('tick-squire-icon',{staticClass:"mx-2"}),_vm._v(" "+_vm._s(_vm.$t('common.to_active'))+" ")],1):_vm._e()]):_vm._e()],1)]}}],null,false,4041680051)}):_vm._e(),(_vm.getAllCoupons && _vm.getAllCoupons.length < 1)?_c('wameed-no-data',{attrs:{"icon":"nodata-icon","title":_vm.$t('packages.no_data'),"sub-title":_vm.$t('packages.no_data_text')}}):_vm._e()],1)]),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[(_vm.getTotalCoupons)?_c('wameed-pagination',{attrs:{"page":_vm.filterData.page,"total-items":_vm.getTotalCoupons.totalItems,"per_page":_vm.filterData.per_page},on:{"changePage":_vm.changePage,"changeCurrentPage":_vm.changeCurrentPage},model:{value:(_vm.filterData.page),callback:function ($$v) {_vm.$set(_vm.filterData, "page", $$v)},expression:"filterData.page"}}):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }